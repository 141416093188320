import { observer } from 'mobx-react-lite';
// import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Dropdown } from 'semantic-ui-react';
import { useStore } from '../stores/store';

export default observer(function NavBar() {
    const { userStore: { user, logout } } = useStore();

    return (
        <div className='navbarContainer'>
            <div style={{ display: 'flex' }}>
                <NavLink to="/accountApprove" activeStyle={{ fontWeight: "bold", color: "red" }} style={{ display: 'flex' }}>
                    <img src={`/assets/DAIMLER_LOGO.png`} alt='logo' width='200px'></img>
                </NavLink>


                <div style={{ marginLeft: '20px' }}>
                    {<Menu.Item as={NavLink} className='navBarLink' to='/accountApprove' content='Firma Sahibi Onay' name='Firma Sahibi Onay' />}
                    {<Menu.Item as={NavLink} className='navBarLink' to='/postApprove' content='İnteraktif Paylaşımlar' name='İnteraktif Paylaşımlar' />}
                    {<Menu.Item as={NavLink} className='navBarLink' to='/driverApprove' content='Sürücü Onay' name='Sürücü Onay' />}
                </div>

            </div>

            <div className='userNameContainer'>
                <Dropdown className='userNameLink' pointing='top left' text={user?.username}>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={logout} text='Çıkış Yap' icon='power' />
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
})