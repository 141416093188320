import { boolean } from "yup";

export interface NonKAApprovmentListResultDto {
  nonKAApprovmentList: NonKAApprovmentResultDTO[];
}

export interface NonKAApprovmentResultDTO {
  id: string;
  name: string;
  surname: string;
  vkn: string;
  tckn: string;
  companyName: string;
  createdDate: string;
  approvedDate: string;
  status: string;
  timePassed: string;
}


export interface NonKAApprovmentHistoryResultDTO {
  nonKAApprovmentHistory: NonKAApprovmentHistoryDTO[];
  // currentPage: number;
  // totalPageCount: number;
}

export interface NonKAApprovmentHistoryDTO {
  id: string;
  status: string;
  modifiedAt: string;
  modifiedUser: string;
}

export interface NonKAApprovmentDetailDTO {
  id: string;
  name: string;
  surname: string;
  phoneNumber: string;
  companyName: string;
  vkn: string;
  tckn: string;
  status: string;
  description: string;

  circularOfSignatureVerified: boolean;
  taxCertificateVerified: boolean;
  identityCardVerified: boolean;

  identityCardFront: string;
  circularOfSignature: string;
  circularOfSignature2: string;
  circularOfSignature3: string;
  taxCertificate: string;

}


export class NonKaAproveDTO {
  phoneNumber: string = '';
  companyName: string = '';
  companyTaxNumber: string = '';
  companyOwnerIdentity: string = '';

  constructor(
  ) {
    this.phoneNumber = '';
    this.companyName = '';
    this.companyTaxNumber = '';
    this.companyOwnerIdentity = '';
  }
}
export class NonKaDenyDTO {
  phoneNumber: string = '';
  description: string = '';

  constructor(
  ) {
    this.phoneNumber = '';
    this.description = '';
  }
}

export class NonKaSendBackDTO {
  phoneNumber: string = '';
  identityCardVerified: boolean = true;
  circularOfSignatureVerified: boolean = true;
  taxCertificateVerified: boolean = true;
  description: string = '';

  constructor(
  ) {
    this.phoneNumber = '';
    this.identityCardVerified = true;
    this.circularOfSignatureVerified = true
    this.taxCertificateVerified = true;
    this.description = '';
  }
}

export interface NonKeyResponseDTO {
  isSuccess: boolean;
  value: any;
}






//Post Approvment

export interface PostApprovmentListResultDto {
  postList: PostApprovmentResultDTO[];
}

export interface PostApprovmentResultDTO {
  id: string;
  userId: string;
  userPhoneNumber: string;
  userName: string;
  userSurname: string;
  userType: string;
  vehicleType: string;
  status: string;
  createdAt: string;
  approvedAt: string;
}




export interface PostApprovmentDetailDTO {
  id: string;
  userId: string;
  userPhoneNumber: string;
  userName: string;
  userSurname: string;
  status: string;
  approvedAt: string;
  createdAt: string;
  url: string;
  description: string;
  location: string;
}


export class PostAproveDTO {
  id: string = '';
  constructor(
  ) {
    this.id = '';
  }
}
export class PostDenyDTO {
  id: string = '';
  constructor(
  ) {
    this.id = '';
  }
}
export class BlacklistUserDTO {
  id: string = '';
  constructor(
  ) {
    this.id = '';
  }
}






// "nonKAApprovmentList": [
//   {
//     "id": "2520448c-c2e9-4fa6-8bf7-219c804e7a41",
//     "name": "Test",
//     "surname": "Test",
//     "vkn": null,
//     "tckn": null,
//     "companyName": null,
//     "createdDate": "2023-09-04 13:45:02.8300926",
//     "approvedDate": null,
//     "status": "0"
//   },























export interface SiparisResultDto {
  siparisListesi: SiparisListItem[];
  siparisIdListesi: siparisChangeListesi[];
  currentPage: number;
  totalPageCount: number;
}
export interface SiparisListItem {
  id: number;
  parcaNo: string;
  statuKodu: number;
  statuKoduAciklama: string;
  siparisAdedi: number;
  disponent: string;
  tedarikciNo: number;
  teslimatNoktasi: string;
  spicsSiparisNo: string | undefined;
  yeniParcaNo: string;
  mbtCikisTarihi: string;
  istenenCikisTarihi: string;
  spicsMusteriNo: string;
  glcSiparisNo: string;
  glcBakiye: number;
  tedarikciSiparisTarihi: string;
  aciklama: string;
  tedarikciTeslimTarihi: string | undefined;
  minimumPaketMiktari: number;
}

export class yeniParcaNoGuncelleDto {
  eskiParcaNo: string = '';
  yeniParcaNo: string = '';

  constructor(
  ) {
    this.eskiParcaNo = '';
    this.yeniParcaNo = '';
  }
}

export class siparisEdit {
  siparisDegisenAlanListesi: changedSiparis[] | undefined = [];
  constructor(
  ) {
    this.siparisDegisenAlanListesi = [];
  }
}

export interface siparisChangeListesi {
  id: number;
  // spicsSiparisNo: string;
  // tedarikciTeslimTarihi: string;
}

export class changedSiparis {
  id: number = 0;
  spicsSiparisNo: string | undefined = '';
  tedarikciTeslimTarihi: string | undefined = '';

  constructor(
  ) {
    this.id = 0;
    this.spicsSiparisNo = '';
    this.tedarikciTeslimTarihi = '';
  }
}
export class SiparisListRequestDto {
  pageNumber: number = 0;
  mBTBaslangicTarihi: string = '';
  mBTBitisTarihi: string = '';
  tedarikciTTBaslangicTarihi: string = '';
  tedarikciTTBitisTarihi: string = '';
  parcaNo: string = '';
  yeniParcaNo: string = '';
  disponent: string = '';
  statuKodu: string = '';
  tedarikciNo: string = '';
  teslimatNoktasi: string = '';

  constructor() {
    this.pageNumber = 0;
    this.mBTBaslangicTarihi = '';
    this.mBTBitisTarihi = '';
    this.tedarikciTTBaslangicTarihi = '';
    this.tedarikciTTBitisTarihi = '';
    this.parcaNo = '';
    this.yeniParcaNo = '';
    this.disponent = '';
    this.statuKodu = '';
    this.tedarikciNo = '';
    this.teslimatNoktasi = '';
  }
}

export interface ParcaNoDetailDto {
  aciklama: string;
  retmi: boolean;
}

export class ParcaNoGuncelleDto {
  parcaNo: string = '';
  aciklama: string = '';
  retmi: boolean = false;

  constructor(
  ) {
    this.parcaNo = '';
    this.aciklama = '';
    this.retmi = false;
  }
}

export class SiparisStatuGuncelleDto {
  idList: number[] = [];
  statuKodu: string = '';

  constructor(
  ) {
    this.idList = [];
    this.statuKodu = '';
  }
}

export interface SiparisArsivResultDto {
  spicsSiparisNo: string;
  tedarikciTeslimTarihi: string;
  islemTarihi: string;
  kullanici: string;
}

export interface DisponentListResultDto {
  id: string;
  kullaniciKodu: string;
  adSoyad: string;
}

export class TedarikciDetailDto {
  id: number = 0;
  tedarikciNo: number = 0;
  tedarikciMailListesi: string[] = [];
  disponentMailListesi: string[] = [];
  disponent: string = '';

  constructor(
  ) {
    this.id = 0;
    this.tedarikciNo = 0;
    this.tedarikciMailListesi = [];
    this.disponentMailListesi = [];
    this.disponent = '';
  }
}

export class ParametreDetailDto {
  siparisPaylasimPeriyodu: number = 0;
  tedarikcilereIletilecekTarih: number = 0;
  tedarikciSiparisMailGunu: number = 0;
  hatirlatmaMailGunu: number = 0;
  constructor() {
    this.siparisPaylasimPeriyodu = 0;
    this.tedarikcilereIletilecekTarih = 0;
    this.tedarikciSiparisMailGunu = 0;
    this.hatirlatmaMailGunu = 0;
  }
}

export class SpicsNoDto {
  id: number = 0;
  tesis: string = '';
  teslimatNoktasi: string = '';
  musteriNo: string = '';
  constructor() {
    this.id = 0;
    this.tesis = '';
    this.teslimatNoktasi = '';
    this.musteriNo = '';
  }
}

export class SorumlularDto {
  id: number = 0;
  statuKodu: string = '';
  email: string[] = [];
  constructor() {
    this.id = 0;
    this.statuKodu = '';
    this.email = [];
  }
}


export interface TedarikciListResultDto {
  tedarikciNo: number;
  disponentKodu: string;
  tedarikciMailList: string;
  disponentBackupMailList: string;
}

export interface TedarikciFileResultDto {
  hataVarmi: boolean;
  hataDosyaDetay: TedarikciDosyaOkuHataDto[];
}

export interface TedarikciDosyaOkuHataDto {
  tedarikciNo: number;
  disponentKodu: string;
  tedarikciMailList: string;
  disponentBackupMailList: string;
  aciklama: string;
}

// public class TedarikciDosyaOkuHataDto
// {
//     public string TedarikciNo { get; set; }
//     public string DisponentKodu { get; set; }
//     public string TedarikciMailList { get; set; }
//     public string DisponentBackupMailList { get; set; }
//     public string Aciklama { get; set; }
// }


export interface DriverApprovmentListResultDto {
  driverApprovmentList: DriverApprovmentResultDTO[];
}

export interface DriverApprovmentResultDTO {
  id: string;
  name: string;
  surname: string;
  createdDate: string;
  approvedDate: string;
  status: string;
  timePassed: string;
}


export interface DriverApprovmentHistoryResultDTO {
  driverApprovmentHistory: DriverApprovmentHistoryDTO[];
  // currentPage: number;
  // totalPageCount: number;
}

export interface DriverApprovmentHistoryDTO {
  id: string;
  status: string;
  modifiedAt: string;
  modifiedUser: string;
}

export interface DriverApprovmentDetailDTO {
  id: string;
  name: string;
  surname: string;
  phoneNumber: string;
  status: string;
  description: string;
  driverLicenceVerified: boolean;
  driverLicenceFront: string;
  driverLicenceBack: string;

}


export class DriverAproveDTO {
  phoneNumber: string = '';

  constructor(
  ) {
    this.phoneNumber = '';
  }
}
export class DriverDenyDTO {
  phoneNumber: string = '';
  description: string = '';

  constructor(
  ) {
    this.phoneNumber = '';
    this.description = '';
  }
}

export class DriverSendBackDTO {
  phoneNumber: string = '';
  driverLicenceVerified: boolean = true;
  description: string = '';

  constructor(
  ) {
    this.phoneNumber = '';
    this.driverLicenceVerified = true;
    this.description = '';
  }
}

export interface DriverResponseDTO {
  isSuccess: boolean;
  value: any;
}

