import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import ModalStore from "./modalStore";
import UserStore from "./userStore";
import nonKAApproveStore from "./nonKAApproveStore";
import postApproveStore from "./postApproveStore";
import driverApproveStore from "./driverApproveStore";

interface Store {
    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;
    nonKAApproveStore: nonKAApproveStore;
    postApproveStore: postApproveStore;
    driverApproveStore: driverApproveStore;

    // errorStore:ErrorStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    nonKAApproveStore: new nonKAApproveStore(),
    postApproveStore: new postApproveStore(),
    driverApproveStore: new driverApproveStore(),
    // errorStore:ErrorStore,
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}