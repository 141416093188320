import { makeAutoObservable } from "mobx";
import { NonKAApprovmentDetailDTO, NonKAApprovmentHistoryDTO, NonKAApprovmentHistoryResultDTO, NonKAApprovmentListResultDto, NonKAApprovmentResultDTO, NonKaAproveDTO, NonKaDenyDTO, NonKaSendBackDTO } from "../models/models";
import { DriverApprovmentDetailDTO, DriverApprovmentHistoryDTO, DriverApprovmentHistoryResultDTO, DriverApprovmentListResultDto, DriverApprovmentResultDTO, DriverAproveDTO, DriverDenyDTO, DriverSendBackDTO } from "../models/models";
import agent from "../api/agent";

export default class driverApproveStore {

    driverUsers: DriverApprovmentListResultDto  | undefined = undefined;
    driverHistory: DriverApprovmentHistoryResultDTO | undefined = undefined;
    driverUserDetails: DriverApprovmentDetailDTO[] = [];

    loadingInitial = false;
    modalLoadingInitial = false;
    historyLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }

    setLoadingInitial = (val: boolean) => {
        this.loadingInitial = val;
    }
    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }
    setHistoryLoadingInitial = (val: boolean) => {
        this.historyLoadingInitial = val;
    }


    loadDriverUsers = async () => {
        this.setModalLoadingInitial(true);
        try {
            const result = await agent.Drivers.list();
            if (result) {
                this.driverUsers = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;

        } catch (error) {
            console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    getDriverApprovmentDetail = async (id: string): Promise<DriverApprovmentDetailDTO | null> => {
        this.setModalLoadingInitial(true);
        try {
            const result = await agent.Drivers.detail(id);
            return result;
        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    getDriverApprovmentHistory = async (id: string) => {
        this.setHistoryLoadingInitial(true);
        try {
            const result = await agent.Drivers.history(id);
            if (result) {
                this.driverHistory = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;

        } catch (error) {
            console.log(error);
            return false;
        }
        finally {
            this.setHistoryLoadingInitial(false);
        }
    }

    approveDriver = async (req: DriverAproveDTO) => {
        this.setModalLoadingInitial(true);
        try {
            var res = await agent.Drivers.approve(req);
            return res;
        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    denyDriver = async (req: DriverDenyDTO) => {
        this.setModalLoadingInitial(true);
        try {
            var res = await agent.Drivers.deny(req);
            return res;
        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    sendBackDriver = async (req: DriverSendBackDTO) => {
        this.setModalLoadingInitial(true);
        try {
            var res = await agent.Drivers.sendback(req);
            return res;
        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    getImage = async (req: string) => {
        this.setModalLoadingInitial(true);
        try {
            var res = await agent.Drivers.getImage(req);
            return res;
        } catch (error) {
            console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }








}