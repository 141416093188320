import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Confirm, TextArea } from 'semantic-ui-react'
import { useStore } from '../../app/stores/store'
import { NonKaAproveDTO, NonKaDenyDTO, NonKaSendBackDTO } from '../../app/models/models'
import HistroyModal from './HistroyModal'
import ModalInnerContainer from '../../app/common/modals/ModalInnerContainer'
import { toast } from 'react-toastify'
import LoadingComponent from '../../app/layout/LoadingComponent'
import { history } from "../..";
import { DriverAproveDTO, DriverDenyDTO, DriverSendBackDTO } from '../../app/models/models'
import InputErrorMessage from '../components/inputErrorMessage'

interface Props {
  accountId: string;
  handleClose: any;
  data: any;
}

export default observer(function DetailsModal(props: Props) {
  const { driverApproveStore } = useStore();

  const [driverLicenceFront, setdriverLicenceFront] = useState<string>('');
  const [driverLicenceBack, setdriverLicenceBack] = useState<string>('');

  const [accountId, setaccountId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [driverLicenceVerifiedCbx, setdriverLicenceVerifiedCbx] = useState(false);

  const [denyConfirmModalOpen, setDenyConfirmModalOpen] = useState<boolean>(false);
  const [approveConfirmModalOpen, setApproveConfirmModalOpen] = useState<boolean>(false);
  const [sendBackConfirmModalOpen, setSendBackConfirmModalOpen] = useState<boolean>(false);
  const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);

  const [baseURL, setBaseURL] = useState<string>('');


  const showHistoryModal = (id: string) => {
    setHistoryModalOpen(true);
  }

  const closeHistoryModal = () => {
    setHistoryModalOpen(false);
  }

  function CloseSubmit() {
    props.handleClose(false);
  }


  async function DenySubmit() {
    let chbx: boolean = (driverLicenceVerifiedCbx )

    // if (!chbx && description == '') {
    //   toast.error("Reddetme nedeni seçiniz ve açıklama yazınız");
    // }
    if (description == '') {
      toast.error("Açıklama yazınız");
    }
    // else if (!chbx) {
    //   toast.error("Reddetme nedeni seçiniz");
    // }
    else {
      setDenyConfirmModalOpen(true);
    }
  }

  async function ConfirmDenySubmit() {
    try {
      let drdd = new DriverDenyDTO();
      drdd.phoneNumber = phoneNumber;
      drdd.description = description;

      var res = await driverApproveStore.denyDriver(drdd);
      if (res === 200) {
        props.handleClose(true)
        toast.success('İşlem reddedilmiştir.')
        await setTimeout(() => history.go(0), 1000);
      }
      else {
        toast.error("İşlem sırasında bir hata meydana geldi. Lütfen tekrar deneyiniz. ");
      }
      setDenyConfirmModalOpen(false);
    }
    catch (error) {
      console.log(error);
    }

  }

  async function SendBackSubmit() {
    let chbx: boolean = (driverLicenceVerifiedCbx )

    if (!chbx && description == '') {
      toast.error("Geri Gönderme nedeni seçiniz ve açıklama yazınız");
    }
    else if (description == '') {
      toast.error("Açıklama yazınız");
    }
    else if (!chbx) {
      toast.error("Geri Gönderme nedeni seçiniz");
    }
    else {
      setSendBackConfirmModalOpen(true);
    }
  }

  async function ConfirmSendBackSubmit() {
    try {
      let drsbd = new DriverSendBackDTO();
      drsbd.phoneNumber = phoneNumber;
      drsbd.driverLicenceVerified = driverLicenceVerifiedCbx;
      drsbd.description = description;
      var res = await driverApproveStore.sendBackDriver(drsbd);
      if (res === 200) {
        props.handleClose(true)
        toast.success('İşlem geri gönderilmiştir.')
        await setTimeout(() => history.go(0), 1000);
      }
      else {
        toast.error("İşlem sırasında bir hata meydana geldi. Lütfen tekrar deneyiniz. ")
      }
    }
    catch (error) {
      console.log(error);
    }
  }
  async function ApproveSubmit() {
    let chbx: boolean = (driverLicenceVerifiedCbx)
    let errorExist = false;
    let detailsErrorMessage = '';
    // if (chbx && description != '') {
    //   //toast.error('Onaylama sırasında "Geri Gönderme / Reddetme Nedeni" seçilmemelidir ve "Açıklama" alanı boş bırakılmalıdır.');
    //   detailsErrorMessage = detailsErrorMessage + ' Onaylama sırasında "Geri Gönderme / Reddetme Nedeni" seçilmemelidir ve "Açıklama" alanı boş bırakılmalıdır.'
    //   errorExist = true;
    // }
    if (description != '') {
      //toast.error('Onaylama sırasında "Açıklama" alanı boş bırakılmalıdır.');
      detailsErrorMessage = detailsErrorMessage + ' Onaylama sırasında "Açıklama" alanı boş bırakılmalıdır.'
      errorExist = true;
    }
    if (chbx) {
      //toast.error('Onaylama sırasında "Geri Gönderme / Reddetme Nedeni" seçilmemelidir.');
      detailsErrorMessage = detailsErrorMessage + ' Onaylama sırasında "Geri Gönderme / Reddetme Nedeni" seçilmemelidir.'
      errorExist = true;
    }
    
    if (errorExist) {
      toast.error(detailsErrorMessage);
    }
    else {
      setApproveConfirmModalOpen(true);
    }
  }

  async function ConfirmApproveSubmit() {
    try {
      let drad = new DriverAproveDTO();
      drad.phoneNumber = phoneNumber;

      var res = await driverApproveStore.approveDriver(drad);

      if (res === 200) {
        props.handleClose(true)
        toast.success('İşlem onaylanmıştır.')
        await setTimeout(() => history.go(0), 1000);
      }
      else {
        toast.error("İşlem sırasında bir hata meydana geldi. Lütfen tekrar deneyiniz. ")
      }
      setApproveConfirmModalOpen(false);
    }
    catch (error) {
      console.log(error);
    }
  }
  const handleCancel = () => {
    setDenyConfirmModalOpen(false);
    setApproveConfirmModalOpen(false);
    setSendBackConfirmModalOpen(false);
  }

  useEffect(() => {
    const currentURL = window.location.href;
    const pathname = window.location.pathname;
    const basePath = currentURL.replace(pathname, '');
    setBaseURL(basePath);
    const fetchData = async () => {
      const data = await driverApproveStore.getDriverApprovmentDetail(props.accountId);
      if (!data) {
        toast.error("Kayıt bulunamadı");
        props.handleClose(false);
        return;
      }
      var mydata = JSON.parse(JSON.stringify(data)).data;
      setaccountId(data.id)
      setName(data.name)
      setSurname(data.surname)
      setPhoneNumber(data.phoneNumber)
      setDescription(data.description)

      setdriverLicenceFront(data.driverLicenceFront);
      setdriverLicenceBack(data.driverLicenceBack);

      setdriverLicenceVerifiedCbx(!data.driverLicenceVerified)
    }
    fetchData()
      .catch(console.error);
  }, [])






  if (driverApproveStore.modalLoadingInitial) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>
      <ModalInnerContainer width={'1000px'} body={<HistroyModal accountId={props.accountId} handleClose={closeHistoryModal} data={''} />} modalTitle='Tarihçe' open={historyModalOpen} closeModal={closeHistoryModal} />
      <Confirm className='denyConfirmModal'
        open={denyConfirmModalOpen}
        content='İşlem reddedilecektir. Devam etmek istiyor musunuz?'
        cancelButton='Vazgeç'
        confirmButton="Tamam"
        onCancel={handleCancel}
        onConfirm={() => { ConfirmDenySubmit(); }}
        size='mini'
      />
      <Confirm className='approveConfirmModal'
        open={approveConfirmModalOpen}
        content='İşlem onaylanacaktır. Devam etmek istiyor musunuz?'
        cancelButton='Vazgeç'
        confirmButton="Tamam"
        onCancel={handleCancel}
        onConfirm={() => { ConfirmApproveSubmit(); }}
        size='mini'
      />
      <Confirm className='sendBackConfirmModal'
        open={sendBackConfirmModalOpen}
        content='İşlem geri gönderilecektir. Devam etmek istiyor musunuz?'
        cancelButton='Vazgeç'
        confirmButton="Tamam"
        onCancel={handleCancel}
        onConfirm={() => { ConfirmSendBackSubmit(); }}
        size='mini'
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='formTable' style={{ width: '50%', padding: '20px' }}>
          <div className='header' style={{ textAlign: 'left' }}>Sürücü Bilgileri</div>
          <hr className='modalLine'></hr>
          <table style={{ padding: "0" }}>
            <tbody>
              <tr>
                <td>Sürücü İsim</td>
                <td>

                  <input className='ActiveInput' type='text'
                    style={{ width: '200px' }} onChange={(event) => { setName(event.target.value); }} value={(name == null) ? '' : name} />
                </td>
              </tr>
              <tr>
                <td>Sürücü Soyisim</td>
                <td>
                  <input className='ActiveInput' type='text'
                    style={{ width: '200px' }} onChange={(event) => { setSurname(event.target.value); }} value={(surname == null) ? '' : surname} />
                </td>
              </tr>
              <tr>
                <td>Sürücü Cep No</td>
                <td>
                  <input className='DisabledInput' type='text' style={{ width: '200px' }} disabled onChange={(event) => { setPhoneNumber(event.target.value); }} value={(phoneNumber == null) ? '' : phoneNumber} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='formTable' style={{ width: '45%', padding: '20px' }}>
          <div className='header' style={{ textAlign: 'left' }}>Yüklenen Belgeler</div>
          <hr className='modalLine'></hr>
          <div className="uploadedItems" style={{ display: "flex", flexDirection: "column" }}>
            <a target="_blank" href={`${baseURL}/image?img=${driverLicenceFront}`} style={{ padding: "10px" }}>Sürücü Belgesi Ön Yüz</a>
            <a target="_blank" href={`${baseURL}/image?img=${driverLicenceBack}`} style={{ padding: "10px" }}>Sürücü Belgesi Arka Yüz</a>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='formTable' style={{ width: '50%', padding: '20px' }}>
          <div className='header' style={{ textAlign: 'left' }}>Geri Gönderme / Reddetme Nedeni</div>
          <hr className='modalLine'></hr>
          <table style={{ padding: "10px" }}>
            <tbody>
              <tr>
                <td>Sürücü belgesi Eksik / Hatalı</td>
                <td>
                  <label className="switch">
                    <input checked={driverLicenceVerifiedCbx} onChange={(event) => { setdriverLicenceVerifiedCbx(event.target.checked); }} name="idendityCardCbx" type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='formTable' style={{ width: '45%', padding: '20px' }}>
          <div className='header' style={{ textAlign: 'left' }}>Açıklama</div>
          <hr className='modalLine' ></hr>
          <div style={{ paddingBottom: '10px' }}></div>
          <TextArea className="ActiveInput" onChange={(event) => { setDescription(event.target.value) }} style={{ width: "100%" }} value={description} />
        </div>
      </div>
      <div className="detailsPageContainer" style={{ padding: "0 0 0 20px" }}>
        <div style={{ marginTop: '30px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit() }} type='submit' value='Kapat' />
              <input className='button mr20 ui buton' onClick={() => { showHistoryModal(accountId); }} type='submit' value='Tarihçe' />
            </div>
            <div style={{ display: 'flex' }}>
              <input className='button mr20 return-button' onClick={() => { SendBackSubmit() }} type='submit' value='Geri Gönder' />
              <input className='button mr20 reject-button' onClick={() => { DenySubmit() }} type='submit' value='Ret' />
              <input className='button mr20 confirm-button' onClick={() => { ApproveSubmit() }} type='submit' value='Onay' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
})