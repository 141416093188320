import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Checkbox, Form, Table, TextArea } from 'semantic-ui-react'
import { DriverApprovmentHistoryDTO } from '../../app/models/models';
import { useStore } from '../../app/stores/store';
import { toJS } from 'mobx';


interface Props {
  accountId: string;
  handleClose: any;
  data: any;
}

export default observer(function HistoryModal(props: Props) {
  const { driverApproveStore } = useStore();


  const [historyData, setHistoryData] = useState<DriverApprovmentHistoryDTO[] | undefined>();




  useEffect(() => {
    const fetchData = async () => {
      await driverApproveStore.getDriverApprovmentHistory(props.accountId);
      if (driverApproveStore.driverHistory) {
        const myhistory = JSON.parse(JSON.stringify(driverApproveStore.driverHistory.driverApprovmentHistory))

        setHistoryData(myhistory)
      }
    }
    fetchData()
      .catch(console.error);
  }, [])



  return (

    <div className="HistoryTable">
      <table>
        <thead>
          <tr><th>İşlemi Yapılan Kullanıcı</th>
            <th>İşlem Statüsü</th>
            <th>İşlem Tarihi</th>
          </tr></thead>
        <tbody>
          {historyData?.map(history => (
            <tr key={history.id}>
              <td>{history.modifiedUser}</td>
              {(history.status == "US_DRV_DOCUMENTS_IN_REVIEW") ? <td>Onay Bekliyor</td>
                : (history.status == "US_DENIED") ? <td>Reddedildi</td>
                  : (history.status == "US_DRV_DOCUMENT_REJECTED") ? <td>Döküman Bekliyor</td>
                    : (history.status == "US_TEMPORARY_PASSWORD") ? <td>Giriş Yapılması Bekleniyor</td>
                      : (history.status == "US_READY")? <td>Onaylandı</td>:  <td>{history.status}</td>}



              <td>{history.modifiedAt} </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>



  )

})